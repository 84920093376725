.footer {
  background-color: $aqua;
  min-height: 8.5rem;

  &__paragraph {
    @include font-style(1.4rem, 400, normal, 1.8rem, $black);
    font-family: MiloComp-Light;
    padding-top: 2rem;
    margin: 0 0 1.1rem 0;
  }

  &__details {
    @include flex(center, space-between);

    &__button {
      &__link {
        @include font-style(1.4rem, 400, normal, 3.2, $primary-color);

        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    &--padding--font-size {
      font-size: 1.2rem;
      padding-top: 2rem;
    }

    &__copyrights {
      @include flex(flex-start, initial);
      @include font-style(1.4rem, 400, normal, 2rem, $grey);
      font-family: "Core Mellow Regular";
      padding-top: 2rem;
      margin: 0;

      img {
        margin-left: 0.7rem;
      }
    }
  }
}
