.login {
  margin-bottom: 14rem;

  &__entry-paragraph {
    @include font-style(1.6rem, 400, normal, 2rem, $coal);
    font-family: "Core Mellow Regular";
    letter-spacing: 0.0025em;
    max-width: 57.8rem;
    margin-bottom: 7.1rem;
  }

  &__form-paragraph {
    @include font-style(1.6rem, 500, normal, 2rem, $coal);
    letter-spacing: 0.0025em;
    font-family: "Core Mellow Medium";
    margin-bottom: 1.3rem;
  }

  &__form {
    margin-bottom: 7.1rem;

    input {
      @include font-style(1.6rem, 350, normal, 2.1rem, $dark-grey);
      font-family: "MiloComp-Light";
      padding: 0.7rem 4.2rem 0.7rem 1.5rem;
      border: 0.1rem solid $coal;
      margin-right: 1.2rem;
      outline: 0;
    }

    &__button {
      font-family: "Core Mellow Medium";
      font-weight: 500;
      padding: 0 1rem;
      display: initial;

      img {
        width: 2.4rem;
        height: 2.4rem;
        object-fit: none;
      }
    }

    &__error-message {
      @include font-style(1.6rem, 500, normal, 2rem, $primary-color);
      font-family: "Core Mellow Medium";
      letter-spacing: 0.0025em;
      margin: 2.4rem 0 2.7rem 0;
    }

    &__information {
      @include font-style(1.4rem, 350, normal, 1.8rem, $black);
      font-family: "MiloComp-Light";
      margin: 1.9rem 0 0 0;
    }
  }

  &__register-paragraph {
    @include font-style(1.4rem, 350, normal, 1.8rem, $black);
    font-family: "MiloComp-Light";
    margin-bottom: 1.1rem;
  }

  &__register-link {
    @include font-style(1.4rem, 500, normal, 3.2rem, $primary-color);
    font-family: "Core Mellow Medium";

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
