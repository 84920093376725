// Flex properties
@mixin flex($align-items, $justify-content) {
    display: flex;
    align-items: $align-items;
    justify-content: $justify-content;
}
  
// Font properties
@mixin font-style($font-size, $font-weight, $font-style, $line-height, $color) {
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
    line-height: $line-height;
    color: $color;
}