.option__list {
  width: 41.5rem;

  &:nth-child(3) {
    margin-left: 1.7rem;
  }

  .react-select {
    &__control {
      min-height: 3.5rem;
      padding: 0 0.7rem 0 1.5rem;
      border: 0.1rem solid #141a17;
      border-radius: unset;
      box-shadow: none;
      cursor: text;

      &:hover {
        border-color: inherit;
      }
    }

    &__input-container,
    &__placeholder {
      @include font-style(1.6rem, 400, normal, 2.1rem, $dark-grey);
      font-family: "MiloComp-Light";
      margin: 0;
    }

    &__input-container {
      padding: 0;
      height: 100%;
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      @include font-style(1.6rem, 400, normal, 2.1rem, $primary-color);
      font-family: "MiloComp-Medium";
      margin: 0;
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      box-shadow: 0 0.5rem 2rem 0 rgba(20, 26, 23, 0.1);
      margin: 0.4rem 0;

      &-list {
        padding: 0;
        border-radius: 0.8rem;
      }
    }

    &__option,
    &__menu-notice--no-options {
      @include font-style(1.6rem, 400, normal, 2.1rem, $dark-grey);
      font-family: "MiloComp-Light";
      background-color: $white;
      padding: 0.7rem 1.5rem;
    }

    &__option {
      cursor: pointer;

      &:active,
      &:hover {
        color: $primary-color;
        background: linear-gradient(
            0deg,
            rgba(143, 143, 145, 0.1),
            rgba(143, 143, 145, 0.1)
          ),
          $white;
      }
    }

    &__menu-notice--no-options {
      cursor: default;
    }
  }
}
