.eurotax__number {
  @include flex(center, initial);
  margin-bottom: 1.3rem;

  label,
  button {
    @include font-style(1.6rem, 500, normal, 2.1rem, $primary-color);
    font-family: "Core Mellow Medium";
  }

  label {
    @include flex(center, initial);
    margin-right: 1.1rem;

    input,
    span {
      @include font-style(1.6rem, 500, normal, 2.1rem, $primary-color);
      font-family: "MiloComp-Medium";
      border: 0.1rem solid $coal;
    }

    input {
      width: 39.1rem;
      padding: 0.7rem 0 0.7rem 1.5rem;
      border-right: 0;
      margin: 0 0 0 2.3rem;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 100rem $white inset !important;
      }

      &::placeholder {
        @include font-style(1.6rem, 350, normal, 2.1rem, $dark-grey);
        font-family: "MiloComp-Light";
      }

      /* Works for Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Works for Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    span {
      width: 2.4rem;
      height: 3.7rem;
      padding: 0.7rem 1.5rem 0.8rem 0;
      border-left: 0;
      color: $black;
      cursor: pointer;
    }
  }
}
