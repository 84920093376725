// Colors
$primary-color: #d22d76;
$secondary-color: #b2c853;
$purple: #7b61ff;
$aqua: #ecf1f0;
$black: #000000;
$white: #ffffff;
$grey: #8f8f91;
$light-grey: #b7b7b7;
$dark-grey: #6c757d;
$coal: #141a17;
$yellow: #ffa900;
