.description--overlay {
  @include flex(center, center);
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .description {
    max-width: 96.8rem;
    background-color: $white;
    padding: 2.3rem 2.3rem 4rem 4rem;
    margin: 0 auto;
    box-shadow: 0 0.4rem 0.4rem 0 #00000040;

    &__close {
      text-align: right;
      margin-bottom: 0.2rem;

      img {
        cursor: pointer;
      }
    }

    &__details {
      h3 {
        @include font-style(1.6rem, 400, normal, 2.3rem, $black);
        font-family: "Core Mellow Regular";
        margin-bottom: 2rem;
      }

      &__statuses {
        @include flex(center, initial);

        &__buttons {
          margin-right: 2.7rem;

          &__button {
            @include flex(center, center);
            padding: 0 1.2rem;
            border-radius: 2rem;

            &:first-of-type {
              background-color: $secondary-color;
            }

            &:nth-of-type(2) {
              background-color: $purple;
            }

            &:nth-of-type(3) {
              background-color: $grey;
            }

            &:nth-of-type(4) {
              background-color: $yellow;
            }

            &:last-of-type {
              background-color: $primary-color;
            }

            &:not(:last-of-type) {
              margin-bottom: 1.5rem;
            }

            span {
              @include font-style(1.4rem, 400, normal, 3.2rem, $white);
              font-family: "MiloComp-Medium";
              letter-spacing: 0.02em;
              margin-left: 0.5rem;
            }
          }
        }

        &__explained {
          p {
            @include font-style(1.6rem, 400, normal, 2rem, $black);
            font-family: "MiloComp-Light";

            &:not(:last-of-type) {
              margin: 0 0 2.8rem 0;
            }

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
