@font-face {
  font-family: "Core Mellow Regular";
  src: local("Core Mellow"),
    url("../../fonts/Core Mellow 45 Regular.otf") format("opentype");
}

@font-face {
  font-family: "Core Mellow Medium";
  src: local("Core Mellow"),
    url("../../fonts/Core Mellow 55 Medium.otf") format("opentype");
}

@font-face {
  font-family: "MiloComp-Light";
  src: local("MiloComp-Light"),
    url("../../fonts/MiloComp-Light.ttf") format("truetype");
}

@font-face {
  font-family: "MiloComp-Medium";
  src: local("MiloComp-Medium"),
    url("../../fonts/MiloComp-Medium.ttf") format("truetype");
}
