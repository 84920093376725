.search {
  margin-bottom: 6.8rem;

  &__banner {
    @include flex(center, initial);
    margin-bottom: 2.5rem;

    &__label {
      @include font-style(1.6rem, 500, normal, 2.1rem, $primary-color);
      font-family: "Core Mellow Medium";
      margin-right: 3.7rem;
    }

    &__button {
      @include font-style(1.6rem, 400, normal, 2.1rem, $light-grey);
      font-family: "MiloComp-Medium";
      background-color: $white;
      border: unset;
      padding: 0;
      margin-left: 2rem;
    }
  }
}
