.board__wrapper {
  .board {
    width: 100%;
    margin-bottom: 3.5rem;

    thead {
      th {
        @include font-style(1.6rem, 400, normal, 2rem, $coal);
        font-family: 'Core Mellow Regular';
        letter-spacing: 0.0025em;
        padding-bottom: 0.9rem;
        width: 6%;

        &:last-of-type {
          img {
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      padding-left: 0.1rem;

      tr {
        border-bottom: 0.1rem solid #d4d4d4;

        td {
          @include font-style(1.4rem, 400, normal, 2rem, $coal);
          font-family: 'MiloComp-Light';
          letter-spacing: 0.0025em;
          padding: 1.1rem 0rem;

          &:first-of-type {
            font-family: 'MiloComp-Medium';
          }

          &:not(:last-of-type) {
            padding-right: 0.5rem;
          }

          .category {
            text-align: center;
            background-color: $aqua;
            padding: 0 1rem;
            border-radius: 2rem;

            span {
              @include font-style(1.4rem, 400, normal, 3.2rem, $coal);
              font-family: 'MiloComp-Medium';
              letter-spacing: 0.02em;
            }
          }

          .status {
            @include flex(center, center);
            background-color: $secondary-color;
            border-radius: 2rem;
            cursor: pointer;

            img {
              margin-right: 0.5rem;
            }

            span {
              @include font-style(1.4rem, 400, normal, 3.2rem, $white);
              font-family: 'MiloComp-Medium';
              letter-spacing: 0.02em;
            }
          }

          .nationalCode {
            color: #141a17;
          }
        }
      }
    }
  }

  .button {
    margin-bottom: 16.4rem;

    &__link {
      @include font-style(1.6rem, 400, normal, 2.1, $primary-color);
    }
  }
}
