.header {
  @include flex(center, space-between);
  margin: 3.8rem 0 3.7rem 0;

  &__heading {
    @include font-style(2.8rem, 400, normal, 4rem, $black);
    font-family: "Core Mellow Regular";
    cursor: pointer;
  }

  &__buttons {
    button {
      display: block;
      width: 100%;
    }
  }
}
