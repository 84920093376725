.button {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__link {
    @include flex(center, initial);
    @include font-style(2rem, 400, normal, 3.2rem, $primary-color);
    font-family: "Core Mellow Regular";
    letter-spacing: 0.02em;
    text-decoration: none;

    &:hover {
      color: $primary-color;
    }

    img {
      margin-right: 0.5rem;
    }
  }
}
